import React, { Fragment, useEffect, useRef, useState } from 'react';
import arrow from '../images/icons/chevron-accordion.svg';
import arrowUp from '../images/icons/icon-top-chevron.svg';
import Burger from './layout/burger';

export const DESKTOP_HEADER_OFFSET = 20;
export const MOBILE_HEADER_OFFSET = 56;

const ScrollSpy = ({ showContent, updateOnChange = false, preComponents = null }) => {
  const ref = useRef();
  const scrollspyRef = useRef();
  const [firstId, setFirstId] = useState('');
  const [mounted, setMounted] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [activeId, setActiveId] = useState('');
  const [scrolling, setScrolling] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [headersTree, setHeadersTree] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  // This function ensures that the given element is visible within its container.
  const scrollIntoViewWithin = (container, element) => {
    const containerRect = container?.getBoundingClientRect();
    const elementRect = element?.getBoundingClientRect() || 0;
    if (elementRect.top < containerRect.top) {
      // If the element is above the container's viewport, scroll up.
      container.scrollTop -= containerRect.top - elementRect.top;
    } else if (elementRect.bottom > containerRect.bottom) {
      // If the element is below the container's viewport, scroll down.
      container.scrollTop += elementRect.bottom - containerRect.bottom;
    }
  };

  // Function to update headers
  const updateHeaders = () => {
    const renderedHeaders = Array.from(
      document.querySelectorAll('[data-js-body] h1, [data-js-body] h2, [data-js-body] h3')
    );
    setHeaders(renderedHeaders);
    let extractedHeaders = {};
    let H1 = '';
    let H2 = '';
    let H3 = '';
    renderedHeaders.forEach((header) => {
      switch (header.nodeName) {
        case 'H1':
          H1 = header.textContent;
          extractedHeaders[header.id] = [H1];
          break;
        case 'H2':
          H2 = header.textContent;
          extractedHeaders[header.id] = [H1, H2];
          break;
        case 'H3':
          H3 = header.textContent;
          extractedHeaders[header.id] = [H1, H2, H3];
          break;
      }
    });
    setHeadersTree(extractedHeaders);
  };

  // Set page is mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // Get headers array after page is mounted
  useEffect(() => {
    if (showContent && mounted) {
      updateHeaders();
    }
  }, [showContent, mounted]);

  // Append Scrollspy Aside dynamically after ensure page mounted and headers array not empty
  useEffect(() => {
    if ((!showContent && headers.length <= 0) || !mounted) return;

    const list = document.createElement('ul');
    let currentH1Item = null;
    let currentH2Accordion = null;

    headers.forEach((header, index) => {
      if (index === 0) setFirstId(header.id);
      if (header.id.includes('data-hidden')) return;

      const listItem = document.createElement('li');
      listItem.classList.add('top-heading', 'pb-2', 'text-balance');

      const textContent = header.textContent; // Get plain text content of header
      const imageContent = header.querySelector('img'); // Get image content if exists

      const button = document.createElement('button');
      const smoothScroll = (event) => {
        const activeButton = event.target;
        // Toggle accordion from parent button "Collapsibe one has [data-open] attribute"
        if (activeButton.hasAttribute('data-open')) {
          if (activeButton.getAttribute('data-open') === 'false') {
            activeButton.nextSibling.classList.remove('hidden');
            activeButton.setAttribute('data-open', 'true');
          } else {
            activeButton.nextSibling.classList.add('hidden');
            activeButton.setAttribute('data-open', 'false');
          }
        }
        const headerElement = document.getElementById(`${header.id}`);
        window.history.replaceState({}, '', `#${header.id}`);
        let height = headerElement?.parentElement?.parentElement?.clientHeight || 0;
        // Sanity check
        if (height > 300) {
          height = 0;
        }
        const top =
          headerElement?.getBoundingClientRect().top +
          window?.scrollY -
          (window?.innerWidth < 1024 ? height + MOBILE_HEADER_OFFSET : DESKTOP_HEADER_OFFSET);
        const oldTop = window?.localStorage.getItem('top');
        if (Math.abs(top - parseInt(oldTop)) <= 20) return;
        setScrolling(true);
        setShowMobileNav(false);
        window?.localStorage.setItem('top', top);
        window?.scrollTo({
          behavior: 'smooth',
          top: top,
        });
        setTimeout(() => setScrolling(false), 700);
      };
      button.addEventListener('click', smoothScroll); // Add dynamic click event at every Item for smooth scrolling
      button.setAttribute('data-scroll-id', `${header.id}`);
      button.classList.add('relative', 'leading-normal', 'w-full', 'group', 'text-left');
      button.textContent = textContent;
      const arrowIcon = document.createElement('img');
      const toggleAccordion = (event) => {
        // prevent parent button click when click the Arrow
        event.preventDefault();
        event.stopPropagation();
        const parentButton = event.target.parentNode;
        const isOpen = parentButton.getAttribute('data-open');
        // toggle Arrow
        isOpen == 'true'
          ? parentButton.setAttribute('data-open', 'false')
          : parentButton.setAttribute('data-open', 'true');
        //toggle child Accordion
        parentButton.nextSibling.classList.contains('hidden')
          ? parentButton.nextSibling.classList.remove('hidden')
          : parentButton.nextSibling.classList.add('hidden');
      };
      arrowIcon.addEventListener('click', toggleAccordion);
      arrowIcon.src = arrow;
      arrowIcon.classList.add(
        'w-6',
        'h-6',
        'absolute',
        'top-1/2',
        '-translate-y-1/2',
        'right-0',
        'cursor-pointer',
        "group-[&[data-open='true']]:rotate-180"
      );
      // Clone image and append it to the button if exists
      if (imageContent) {
        const clonedImage = imageContent.cloneNode(true);
        clonedImage.classList.add('w-4', 'h-4');
        button.prepend(clonedImage);
      }

      listItem.appendChild(button);

      if (header.nodeName === 'H1') {
        listItem.classList.add('ml-2', 'font-semibold', 'text-base');
        list.appendChild(listItem);
        currentH1Item = listItem;
      } else if (header.nodeName === 'H2') {
        if (
          currentH1Item &&
          currentH1Item.childNodes[0]?.getAttribute('data-scroll-id')?.includes('data-accordion')
        ) {
          listItem.childNodes[0].append(arrowIcon);
          listItem.childNodes[0].classList.add('pr-6');
          listItem.childNodes[0].setAttribute('data-open', 'false');
        }
        listItem.classList.add('pl-4', 'font-light', 'text-sm');
        const accordion = document.createElement('ul');
        //accordion.classList.add('accordion');
        listItem.appendChild(accordion);
        if (currentH1Item) currentH1Item.appendChild(listItem);
        else list.appendChild(listItem);
        currentH2Accordion = accordion;
      } else if (header.nodeName === 'H3') {
        listItem.classList.add('pl-5');
        if (currentH2Accordion) currentH2Accordion.appendChild(listItem);
        else list.appendChild(listItem);
      }
    });

    if (updateOnChange) {
      if (ref.current) {
        ref.current.innerHTML = '';
        ref.current.appendChild(list);
      }
    } else {
      if (ref.current) {
        ref.current.appendChild(list);
      }
    }
  }, [showContent, headers, mounted]);

  // Apply scrollspy at scroll to detect the headers items top
  useEffect(() => {
    if (!showContent && headers.length <= 0) return;
    const scrollSpy = () => {
      const topOffset = window?.innerWidth < 1024 ? 70 : 30; // As it sticky we put 30px topOffset at desktop and add the height of mobile Nav at mobile
      let active;
      headers.map((header) => {
        const top = header?.getBoundingClientRect().top + window?.scrollY - topOffset;
        if (window?.scrollY >= top) active = header.id;
      });
      setActiveId(active);
      setBreadcrumbs(headersTree[active]);
      // close nav menu if go higher the sticyk top nav
      scrollspyRef.current.getBoundingClientRect().top + window.scrollY <= window.scrollY &&
        setShowMobileNav(false);
    };
    scrollSpy();
    window?.addEventListener('scroll', scrollSpy);
    return () => {
      window?.removeEventListener('scroll', scrollSpy);
    };
  }, [showContent, headers]);

  // Handle switch activity of Scrollspy Aside elements and it is visible at the viewport and parent
  useEffect(() => {
    if (!showContent || headers.length <= 0 || scrolling || !mounted) return;
    // Handle not active case
    const activeElement = activeId
      ? document.querySelector(`[data-scroll-id=${activeId}]`)
      : undefined;

    Array.from(document?.querySelectorAll(`[data-scroll-id]`)).map((item) => {
      item.classList.remove('text-brand-blue-4', 'font-semibold', 'underline'); // Remove highlight using Tailwind CSS from not active element
      if (
        item?.getAttribute('data-open') &&
        activeElement?.getAttribute('data-scroll-id') != item?.getAttribute('data-scroll-id')
      ) {
        item.nextSibling.classList.add('hidden'); // Collapse accordion
        item.classList.remove('text-brand-blue-4', 'font-semibold'); // Remove highlight using Tailwind CSS from not active accordion parent
        item.setAttribute('data-open', 'false'); // Toggle arrow down when accordion is close
      }
    });
    if (!activeId) return;

    // Handle active case
    const activeParent = activeElement?.parentNode?.parentNode;
    if (activeParent && activeParent.classList.contains('accordion')) {
      activeParent.classList.remove('hidden'); // Expand accordion
      activeParent.previousSibling.classList.add('text-brand-blue-4', 'font-semibold'); // Add highlight using Tailwind CSS at active accordion parent
    }
    scrollIntoViewWithin(ref.current, activeElement); // This function ensures that the given element is visible within its container.(aside & active items)
    activeElement?.classList.add('text-brand-blue-4', 'font-semibold', 'underline'); // Add highlight using Tailwind CSS at active element
  }, [showContent, mounted, headers, activeId, scrolling]);

  // Use MutationObserver to detect changes in the DOM
  useEffect(() => {
    if (!updateOnChange) return;
    const observer = new MutationObserver(() => {
      updateHeaders();
    });

    const config = { childList: true, subtree: true };
    const targetNode = document.querySelector('[data-js-body]');

    if (targetNode) {
      observer.observe(targetNode, config);
    }

    return () => {
      if (targetNode) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <nav ref={scrollspyRef} className="relative w-full mx-auto lg:mx-0 lg:h-screen">
      <button
        className={`sticky w-screen px-5 -mx-5 h-14 gap-1 bg-brand-blue-1 border border-slate-200 rounded-sm justify-between items-center ease-in-out transform transition-all duration-1000 lg:transition-none lg:sticky lg:hidden ${
          showContent && breadcrumbs && breadcrumbs.length ? 'flex' : 'hidden'
        }`}
        onClick={() => setShowMobileNav(!showMobileNav)}
      >
        <div className="text-left leading-3 flex flex-row flex-wrap w-full">
          {showContent &&
            breadcrumbs?.map((header, index) => {
              if (header === '') return null;
              return (
                <Fragment key={`breadcrumbs_${index}`}>
                  <div
                    className={`${
                      breadcrumbs.length > index + 1
                        ? 'text-brand-blue-5 text-xs'
                        : 'text-brand-blue-4 text-xs font-bold'
                    } pr-1 flex-none break-inside-avoid text-ellipsis overflow-hidden`}
                  >
                    {header}
                  </div>
                  {breadcrumbs.length > index + 1 && (
                    <div className="align-middle text-brand-blue-5 text-xs pr-1 flex-none break-inside-avoid">{` > `}</div>
                  )}
                </Fragment>
              );
            })}
        </div>
        <div className="h-7 w-7 flex-shrink-0 my-auto">
          <Burger show={showMobileNav} />
        </div>
      </button>
      <div
        className={`scrollspy h-full max-h-full overflow-x-clip overflow-y-auto fixed top-14 left-0 w-full pb-14 bg-white ease-in-out transition-opacity duration-200 lg:h-full lg:min-h-full lg:border-r-2 lg:opacity-100 lg:pointer-events-auto lg:static lg:bottom-auto lg:left-auto lg:translate-x-0 lg:mx-0 lg:pb-0 ${
          showMobileNav ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
      >
        <div className="aside-mask bg-white flex flex-col justify-end h-full pb-6">
          {preComponents}
          <div className="aside p-4 overflow-x-auto xl:p-6 h-full" ref={ref}>
            {!showContent && <ScrollSpySkeleton />}
          </div>
          <div className="px-4 flex flex-col justify-start w-full h-20 xl:px-10">
            <div className="border-t border-slate-200 mb-6"></div>
            <button
              className="flex gap-1.5 items-center"
              onClick={() => {
                setScrolling(true);
                setShowMobileNav(false);
                window?.scrollTo({
                  behavior: 'smooth',
                  top:
                    document?.getElementById(firstId)?.getBoundingClientRect().top +
                    window?.scrollY -
                    (window?.innerWidth < 1024 ? MOBILE_HEADER_OFFSET : 0),
                });
                setTimeout(() => setScrolling(false), 700);
              }}
            >
              <span className="text-xs font-bold uppercase text-brand-blue-4">Back to Top</span>
              <img src={arrowUp} width={12} height={12} alt="arrowUp" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

const ScrollSpySkeleton = () => (
  <div className="animate-pulse flex flex-col w-full ml-2">
    <div className="leading-loose">
      <div className="inline-block w-3/4 bg-brand-gray-2 opacity-35 h-5 rounded"></div>
      <div className="pl-4">
        {Array(5)
          .fill()
          .map((_, index) => (
            <div key={index} className="leading-loose">
              <div className="inline-block w-3/4 bg-brand-gray-2 opacity-35 h-5 rounded"></div>
              <div className="relative pl-5">
                <div className="leading-loose">
                  <div className="inline-block w-3/4 bg-brand-gray-2 opacity-35 h-5 rounded"></div>
                </div>
                <div className="leading-loose">
                  <div className="inline-block w-3/4 bg-brand-gray-2 opacity-35 h-5 rounded"></div>
                </div>
                <div className="leading-loose">
                  <div className="inline-block w-3/4 bg-brand-gray-2 opacity-35 h-5 rounded"></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default ScrollSpy;
