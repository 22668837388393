import { bluePalette, goodPalette, extendedGoodPalette, badPalette, reversedGoodPalette, reversedBadPalette, strongSupport, weakSupport, weakOppose, strongOppose } from "../../../../src/utils/pulsePalette.js";
import direction from "../../../../src/images/icons/direction.svg";
import mayor from "../../../../src/images/icons/mayor.svg";
import updown from "../../../../src/images/icons/updown.svg";
import smallBiz from "../../../../src/images/icons/small-business.svg";
import homeless from "../../../../src/images/icons/homeless.svg";
import wfh from "../../../../src/images/icons/work-from-home.svg";
import downtown from "../../../../src/images/icons/downtown.svg";
import { H1 } from "../../../../src/components/layout/headings";
import { SupportOpposeLineChart } from "../../../../src/components/pulse/lineChart.js";
import * as React from 'react';
export default {
  bluePalette,
  goodPalette,
  extendedGoodPalette,
  badPalette,
  reversedGoodPalette,
  reversedBadPalette,
  strongSupport,
  weakSupport,
  weakOppose,
  strongOppose,
  direction,
  mayor,
  updown,
  smallBiz,
  homeless,
  wfh,
  downtown,
  H1,
  SupportOpposeLineChart,
  React
};