import React from 'react';

const NotAuthorized = () => (
  <span>
    Not authorized by any candidate, candidate's committee, or committee controlled by a candidate.
    Financial disclosures are available at sfethics.org.
  </span>
);

const NotAuthorizedCN = () => (
  <p>
    此廣告不是由候選人或由候選人所控制的委員會所授權 財政披露可瀏覽 sfethics.org.
  </p>
);

const standardDisclaimerClass =
  'not-prose !max-w-2xl !p-5 !mx-auto !mt-8 !text-center !text-black !text-[14pt] !font-pac !border !border-black';

const Disclaimer = ({ paidForBy, donorList, notAuthorized: NotAuthorizedComponent }) => {
  if (NotAuthorizedComponent == null) {
    NotAuthorizedComponent = NotAuthorized;
  }
  return (
    <div className={standardDisclaimerClass}>
      {Array.isArray(donorList)
        ? <span>{paidForBy + ' Committee major funding from: ' + donorList.join(', ') + '. '}</span>
        : <span>{paidForBy + ' '}</span>}
      <NotAuthorizedComponent />
    </div>
  );
};

export const PACDisclaimer = ({}) => (
  <Disclaimer paidForBy="Paid for by GrowSF Voter Guide. FPPC # 1433436."
     donorList={['Jeremy Liew']}
   />
);
